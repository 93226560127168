// Eventually we want to be able to coinfigure this stuff.

import { PermissionEnum, Role, RoleMapType } from './rolesAndPermissions';
export const _defaultRoleMap: RoleMapType = {
  OWNER: {
    OWNER: true,
    SHAREHOLDER: true,
    VIEW_SHARES: true,
    ACCESS_TO_GA_MEETINGS: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    CAN_VOTE: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    OPENAI_USER: true,
    files: true,
    news: true,
    shareholders: true,
    surveys: true,
    meetings: true,
  },
  ADMIN: {
    ADMIN: true,
    SHAREHOLDER_ADMIN: true,
    DATAROOM_ADMIN: true,
    MEETING_ADMIN: true,
    GA_MEETING_ADMIN: true,
    BOARD_MEETING_ADMIN: true,
    TASKS_ADMIN: true,
    NEWS_ADMIN: true,
    GA_NEWS_ADMIN: true,
    USERMANAGER: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    VIEW_SHARES: true,
    CREATE_SIGNING_ORDER: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_EMAILS: true,
    SEE_USER_NAMES: true,
    SELLER_ADMIN: true,
    SEE_ACTION_POINTS: true,
    SEE_ENTITY_SENSITIVE: true,
    BUYER_ADMIN: true,
    SELLER: true,
    BUYER: true,
    DATAROOM_DOWNLOAD_ZIP: true,
    ORGBRAIN_ACADEMY: true,
    DATAROOM_SEE_SHOW_DELETED: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_SEE_FILE_HISTORY: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    TEMPLATES_ADMIN: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    OPENAI_USER: true,
    shareholders: true,
    templates: true,
    files: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
    school: true,
    news: true,
    organization: true,
  },

  ORGBRAIN_SUPPORT: {},
  USER_ADMIN: {
    VIEW_SHARES: true,
    USERMANAGER: true,
    SEE_USER_EMAILS: true,
    SEE_USER_NAMES: true,
    OPENAI_USER: true,
    organization: true,
  },
  CHAIR: {
    ADMIN: true,
    NEWS_ADMIN: true,
    GA_NEWS_ADMIN: true,
    SHAREHOLDER_ADMIN: true,
    MEETING_ADMIN: true,
    GA_MEETING_ADMIN: true,
    BOARD_MEETING_ADMIN: true,
    TASKS_ADMIN: true,
    USERMANAGER: true,
    CAN_VOTE: true,
    CAN_VOTE_BOARDMEETING: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    CREATE_SIGNING_ORDER: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_EMAILS: true,
    SEE_USER_NAMES: true,
    SEE_ENTITY_SENSITIVE: true,
    SEE_ACTION_POINTS: true,
    DATAROOM_DOWNLOAD_ZIP: true,
    ORGBRAIN_ACADEMY: true,
    DATAROOM_SEE_SHOW_DELETED: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_SEE_FILE_HISTORY: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    TEMPLATES_ADMIN: true,
    OPENAI_USER: true,
    school: true,
    shareholders: true,
    templates: true,
    files: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
    news: true,
    organization: true,
    DATAROOM_ADMIN: true,
  },
  BOARDMEMBER: {
    CAN_VOTE: true,
    CAN_VOTE_BOARDMEETING: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_EMAILS: true,
    SEE_USER_NAMES: true,
    SEE_ACTION_POINTS: true,
    ORGBRAIN_ACADEMY: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    OPENAI_USER: true,
    templates: true,
    shareholders: true,
    files: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
    school: true,
    news: true,
  },
  CEO: {
    ADMIN: true,
    NEWS_ADMIN: true,
    GA_NEWS_ADMIN: true,
    SHAREHOLDER_ADMIN: true,
    MEETING_ADMIN: true,
    GA_MEETING_ADMIN: true,
    BOARD_MEETING_ADMIN: true,
    TASKS_ADMIN: true,
    USERMANAGER: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    CREATE_SIGNING_ORDER: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_EMAILS: true,
    SEE_USER_NAMES: true,
    SEE_ENTITY_SENSITIVE: true,
    DATAROOM_DOWNLOAD_ZIP: true,
    ORGBRAIN_ACADEMY: true,
    DATAROOM_SEE_SHOW_DELETED: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_SEE_FILE_HISTORY: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    TEMPLATES_ADMIN: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    SEE_ACTION_POINTS: true,
    OPENAI_USER: true,
    shareholders: true,
    templates: true,
    files: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
    school: true,
    news: true,
    organization: true,
    DATAROOM_ADMIN: true,
  },
  BOARDSECRETARY: {
    ADMIN: true,
    NEWS_ADMIN: true,
    GA_NEWS_ADMIN: true,
    SHAREHOLDER_ADMIN: true,
    MEETING_ADMIN: true,
    GA_MEETING_ADMIN: true,
    BOARD_MEETING_ADMIN: true,
    TASKS_ADMIN: true,
    USERMANAGER: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    CREATE_SIGNING_ORDER: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_EMAILS: true,
    SEE_USER_NAMES: true,
    SEE_ACTION_POINTS: true,
    SEE_ENTITY_SENSITIVE: true,
    DATAROOM_DOWNLOAD_ZIP: true,
    ORGBRAIN_ACADEMY: true,
    DATAROOM_SEE_SHOW_DELETED: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_SEE_FILE_HISTORY: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    TEMPLATES_ADMIN: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    OPENAI_USER: true,
    templates: true,
    shareholders: true,
    files: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
    school: true,
    news: true,
    organization: true,
    DATAROOM_ADMIN: true,
  },
  DEPUTYCHAIR: {
    CAN_VOTE: true,
    CAN_VOTE_BOARDMEETING: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_EMAILS: true,
    SEE_USER_NAMES: true,
    SEE_ACTION_POINTS: true,
    ORGBRAIN_ACADEMY: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    OPENAI_USER: true,
    templates: true,
    shareholders: true,
    files: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
    school: true,
    news: true,
  },
  DEPUTYMEMBER: {
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_EMAILS: true,
    SEE_USER_NAMES: true,
    ORGBRAIN_ACADEMY: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    SEE_ACTION_POINTS: true,
    OPENAI_USER: true,
    templates: true,
    shareholders: true,
    files: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
    school: true,
    news: true,
  },
  OBSERVER: {
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_EMAILS: true,
    SEE_USER_NAMES: true,
    SEE_ACTION_POINTS: true,
    ORGBRAIN_ACADEMY: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    OPENAI_USER: true,
    templates: true,
    shareholders: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
    school: true,
    news: true,
    files: true,
  },
  SHAREHOLDER: {
    SHAREHOLDER: true,
    shareholders: true,
    VIEW_SHARES: true,
    OPENAI_USER: true,
  },
  AUDITOR: {
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    VIEW_SHARES: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_ACTION_POINTS: true,
    DATAROOM_DOWNLOAD_ZIP: true,
    ORGBRAIN_ACADEMY: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    SEE_FOLLOWUP_CASES: true,
    OPENAI_USER: true,
    templates: true,
    meetings: true,
    tasks: true,
    shareholders: true,
    files: true,
    surveys: true,
    chat: true,
    school: true,
    news: true,
  },
  CONTACTPERSON: {},
  SHAREHOLDER_PROXY: {
    OWNER: true,
    SHAREHOLDER: true,
    VIEW_SHARES: true,
    ACCESS_TO_GA_MEETINGS: true,
    ACCESS_TO_NEWS_FOR_SHAREHOLDERS: true,
    CAN_VOTE: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    OPENAI_USER: true,
    files: true,
    shareholders: true,
    meetings: true,
  },
  SCHOOL: {
    ORGBRAIN_ACADEMY: true,
    school: true,
    templates: true,
    OPENAI_USER: true,
  },
  SELLER_ADMIN: {
    SELLER: true,
    SELLER_ADMIN: true,
    ADMIN: true,
    NEWS_ADMIN: true,
    GA_NEWS_ADMIN: true,
    MEETING_ADMIN: true,
    BOARD_MEETING_ADMIN: true,
    TASKS_ADMIN: true,
    GA_MEETING_ADMIN: true,
    USERMANAGER: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    ACCESS_TO_GA_MEETINGS: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_USER_EMAILS: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_NAMES: true,
    SEE_ACTION_POINTS: true,
    SEE_ENTITY_SENSITIVE: true,
    CAN_VOTE: true,
    CAN_VOTE_BOARDMEETING: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    CREATE_SIGNING_ORDER: true,
    SEE_CYCLIC_CALENDAR: true,
    DATAROOM_DOWNLOAD_ZIP: true,
    DATAROOM_SEE_SHOW_DELETED: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_SEE_FILE_HISTORY: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    SHAREHOLDER_ADMIN: true,
    TEMPLATES_ADMIN: true,
    OPENAI_USER: true,
    news: true,
    shareholders: true,
    files: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
    organization: true,
    DATAROOM_ADMIN: true,
  },
  BUYER_ADMIN: {
    BUYER: true,
    BUYER_ADMIN: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    SEE_FOLLOWUP_CASES: true,
    CAN_VOTE: true,
    CAN_VOTE_BOARDMEETING: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_USER_EMAILS: true,
    SEE_ACTION_POINTS: true,
    DATAROOM_DOWNLOAD_ZIP: true,
    DATAROOM_SEE_FILE_HISTORY: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    OPENAI_USER: true,
    shareholders: true,
    surveys: true,
    files: true,
    meetings: true,
    tasks: true,
    chat: true,
  },
  BUYER: {
    BUYER: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    SEE_FOLLOWUP_CASES: true,
    CAN_VOTE: true,
    CAN_VOTE_BOARDMEETING: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    SEE_CYCLIC_CALENDAR: true,
    SEE_USER_EMAILS: true,
    SEE_ACTION_POINTS: true,
    OPENAI_USER: true,
    shareholders: true,
    surveys: true,
    files: true,
    meetings: true,
    tasks: true,
    chat: true,
  },
  SELLER: {
    SELLER: true,
    MEETING_ADMIN: true,
    BOARD_MEETING_ADMIN: true,
    TASKS_ADMIN: true,
    ACCESS_TO_BOARD_MEETINGS: true,
    SEE_FOLLOWUP_CASES: true,
    SEE_USER_EMAILS: true,
    SEE_MANAGE_SIGNATURES: true,
    SEE_USER_NAMES: true,
    SEE_ACTION_POINTS: true,
    CAN_VOTE: true,
    CAN_VOTE_BOARDMEETING: true,
    ACCESS_TO_NEWS_FOR_BOARD: true,
    VIEW_SHARES: true,
    CREATE_SIGNING_ORDER: true,
    SEE_CYCLIC_CALENDAR: true,
    DATAROOM_SEE_SHOW_DELETED: true,
    DATAROOM_COPY_FILE: true,
    DATAROOM_SEE_FILE_DETAILS: true,
    DATAROOM_SEE_FILE_HISTORY: true,
    DATAROOM_CREATE_TASK_FROM_FILE: true,
    DATAROOM_SEE_FILE_MENU: true,
    DATAROOM_SET_FILE_STATUS: true,
    OPENAI_USER: true,
    shareholders: true,
    files: true,
    surveys: true,
    meetings: true,
    tasks: true,
    chat: true,
  },
  ROLE_1: {},
  ROLE_2: {},
  ROLE_3: {},
  ROLE_4: {},
  ROLE_5: {},
  ROLE_6: {},
};

for (const perm of Object.keys(PermissionEnum)) {
  _defaultRoleMap[Role.ORGBRAIN_SUPPORT][perm] = true;
}
