export enum ChannelEnum {
  HEARTBEAT = 'HEARTBEAT',
  // The user notifiction lit has changed.
  UserNotification = 'UserNotification',

  // A user has been modified so we need to rebuild permission etc.
  UserModifyNotification = 'UserModifyNotification',

  // The status of a sign order has change so we can reaload in file views.  orderId
  SigningNotification = 'SigningNotification',

  SigningNotificationV2 = 'SigningNotificationV2',
  ANNOTATION = 'ANNOTATION',

  StressTesting = 'StressTesting',
  RecursiveHardDelete = 'RecursiveHardDelete',
}
