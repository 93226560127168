import { LogLevel, LogSourceEnum } from '@orgbrain/lib-data';

export enum FixStateEnum {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  REFUTED = 'REFUTED',
  FIXED = 'FIXED',
}

export class LogEntry {
  _id?: string;
  id?: string;
  stamp: string; // time
  email: string; // user email if know
  level: LogLevel; // how painful is this
  mess: string; //
  error: any;
  source: LogSourceEnum;
  referer: string;
  agent: string;
  url: string;
  sub: string;
  stack: any;

  build: string;
  ip?: string;
  userId?: string;
  requestId?: string;
  hostname: string;
  processId?: any;
  serverVersion: string;
  service;
  whoToBlame?: string;
  notes?: string;
  fixState?: FixStateEnum;
  hash?: string; // hash code of error to find similar
  duplicateOfId?: string;
  duplicatesCount?: number;
}
