export const schoolLinks = [
  {
    name: 'Om styrekurset',
    route: 'about',
    purchasedoption: false,
    registereduseroption: false,
    adminoption: false,
  },
  // tslint:disable-next-line:max-line-length
  {
    name: 'Kursoversikt',
    route: 'courses/basiskursIStyrearbeid/sections',
    purchasedoption: true,
    registereduseroption: true,
    adminoption: false,
  },
  // tslint:disable-next-line:max-line-length
  {
    name: 'Ta eksamen',
    route: 'courses/basiskursIStyrearbeid/exam',
    purchasedoption: true,
    registereduseroption: true,
    adminoption: false,
  },
  {
    name: 'Min side',
    route: 'profile',
    purchasedoption: true,
    registereduseroption: true,
    adminoption: false,
  },
  {
    name: 'Quiz',
    route: 'quiz',
    purchasedoption: false,
    registereduseroption: false,
    adminoption: false,
  },
  {
    name: 'Admin',
    route: 'admin',
    purchasedoption: true,
    registereduseroption: true,
    adminoption: true,
  },
];
