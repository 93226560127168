export async function sleep(t): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), t));
}

export function addParamToUrl({
  paramName,
  paramValue,
  url,
}: {
  paramName: string;
  paramValue: string;
  url: string;
}) {
  if (url.includes('?')) {
    url += '&';
  } else {
    url += '?';
  }

  url += `${paramName}=${paramValue}`;

  return url;
}

export function getParamFromUrl({ paramName, url }: { paramName: string; url: string }) {
  let index = url.indexOf(paramName + '=');
  if (index !== -1) {
    let param = url.slice(index + paramName.length + 1);

    if (param.indexOf('&') === -1) {
      return param;
    } else {
      return param.slice(0, param.indexOf('&'));
    }
  } else {
    return '';
  }
}

export function printClassName(mess, thing) {
  // console.log(mess, thing.constructor?.name);
}
