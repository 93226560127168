export enum IdentityType {
  no_bankid = 'no_bankid',
  sms = 'sms',
  cozone = 'cozone',
  cognito = 'cognito',
  manual = 'manual', // This is a special case because we store this informtion in OrganizationUserRoles  Not in the user data
  totp = 'totp',
}

export const identityTypeLang = {
  [IdentityType.no_bankid]: {
    en: 'Norwegian BankID',
    nb: 'Norsk BankID',
  },
  [IdentityType.sms]: {
    en: 'SMS',
    nb: 'SMS',
  },
  [IdentityType.cozone]: {
    en: 'Cozone',
    nb: 'Cozone',
  },
  [IdentityType.cognito]: {
    en: 'AWS Cognito',
    nb: 'AWS Cognito',
  },
  // [IdentityType.manual]: {
  //   en: 'Manual identity verification',
  //   nb: 'Manuell identiftetskontroll',
  // },
};

export class IdentityRecord {
  identityType: IdentityType;
  identityId: string;
  data: any;
  history: any[];
  allowLogon: boolean;
  // last verification.
  stamp: string | Date;
  createdAt: string | Date;
}
