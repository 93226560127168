import { CompanyAcronymsEnum, companyAcronymsMap } from '@orgbrain/roles';

export type CompanyType = {
  type: string;
  nb: string;
  en: string;
  selected?: boolean;
};

export const companyTypes: {
  type: CompanyAcronymsEnum;
  nb: string;
  en: string;
  sv?: string;
}[] = Object.keys(CompanyAcronymsEnum).map((key) => {
  const record = { ...companyAcronymsMap[key] };
  record.type = key;
  return record;
});

// console.log(JSON.stringify(companyTypes, null, 2));

export function guessOrgTypeFromOrgName({
  orgName,
  avoidDefaultOrgType,
}: {
  orgName: string;
  avoidDefaultOrgType?: boolean;
}) {
  let result = undefined;
  if (orgName && typeof orgName === 'string') {
    const tokens = orgName.split(/\s+/);
    const lastToken = tokens.length && tokens[tokens.length - 1].toUpperCase();
    const exists = companyTypes.find((item) => item.type === lastToken);
    if (exists) {
      result = lastToken;
    }
  }

  if (!result && !avoidDefaultOrgType) {
    result = 'AS';
  }

  return result;
}
