import { CombinedNotificationPayload, Notifications } from './notifications';

export class ReminderReportItem {
  record: ReminderRecord;
  stamp: number;
}

// Slit the payload inot idices for mongoose search and other any data.
export function extractIndices(payload: any): { indices } {
  const indices: ReminderRecord = {} as any;
  indices.userId = payload.userId || payload.recipient.userId;
  for (const key of Object.keys(payload)) {
    if (Notifications.notificationIndices[key]) {
      indices[key] = payload[key];
    }
  }
  return { indices };
}

// export interface ReminderRecord extends NotificationReminder {
//   _id: string;
//   organizationId: string;
//   meetingId: string;
//   direntId: string;
//   signOrderId: string;
//   requestId: string;
//   taskId: string;
//   userId: string;
//   email: string;
//   payload: any;
// }

export class ReminderState {
  count: number;
  schedule: any;
  timeStamp: number;
}

export type ReminderRecord = {
  _id?: string;
  userId: string;
  code?: string;
  // OPTIONAL KEYS FOR SEARCHING  (keep adding as needed)
  organizationId?: string;
  meetingId?: string;
  direntId?: string;
  signOrderId?: string;
  requestId?: string;
  email: string;
  payload: CombinedNotificationPayload;
  taskId?: string;
  reminderState: ReminderState;
  reminderPayload: any;
  createdAt: Date;
  updatedAt: Date;
};

export type TaskDoneFunc = (reminderRecord: ReminderRecord) => Promise<boolean>;

export interface TaskDoneWrapper {
  eventType: string;
  taskDone: TaskDoneFunc;
}
