export const monitorUrls = {
  production: {
    ws_url: 'wss://monitor-server.orgbrain.ai/wsproduction',
    url: 'https://monitor-server.orgbrain.ai/production',
  },
  testprod: {
    ws_url: 'wss://monitor-server.orgbrain.ai/wstestprod',
    url: 'https://monitor-server.orgbrain.ai/testprod',
  },
  preprod: {
    ws_url: 'wss://monitor-server.orgbrain.ai/wspreprod',
    url: 'https://monitor-server.orgbrain.ai/preprod',
  },
  test: {
    ws_url: 'wss://monitor-server.orgbrain.ai/wstest',
    url: 'https://monitor-server.orgbrain.ai/test',
  },
};
