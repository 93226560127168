import { enableProdMode } from '@angular/core';

import { environment } from 'environments/environment';

import { loadTranslations } from '@angular/localize';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getParamFromUrl } from '@orgbrain/lib-data';

if (environment.production) {
  enableProdMode();
}

// mainly to force 'en' for testing
let lang = environment.defaultLang;
if (!lang) {
  lang = getParamFromUrl({ paramName: 'uselocale', url: window.location.href });
  if (lang) {
    localStorage.setItem('orgbrainLang', lang);
  }
}
if (!lang) {
  lang = localStorage.getItem('orgbrainLang');
}

lang = ['en', 'nb'].includes(lang) ? lang : 'nb';

fetch(`./assets/i18n/messages.${lang}.json`)
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    return response.json();
  })
  .then((result) => {
    loadTranslations(result.translations);

    import('./app/app.module').then((module) => {
      platformBrowserDynamic()
        .bootstrapModule(module.AppModule)
        .catch((err) => console.error('Error in loading translations', err));
    });
  });
