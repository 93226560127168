import { textMappings } from './services';

export enum SigningEvent {
  TASK_CREATED = 'TASK_CREATED',
  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_REJECTED = 'TASK_REJECTED',
  ORDER_CREATED = 'ORDER_CREATED',
  PACKAGING_COMPLETED = 'PACKAGING_COMPLETED',
  TASK_EXPIRED = 'TASK_EXPIRED',
}
export enum NotificationStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
}
export enum NotificationType {
  ACTION_AUTO = 'ACTION_AUTO', // requires an action that will remove notification     No bin.   Remove after time out.
  ACTION_MANUAL = 'ACTION_MANUAL', // requires an action but user must remove notification.
  INFORMATION = 'INFORMATION', // information  user
}
export class NotificationTemplate {
  title: { en: string; nb: string };
  code: string;
  data: any;
  templates?: { en: string; nb: string };
  templatesNoHtml?: { en: string; nb: string };
  type: NotificationType;
}

export const notificationCodes: { [code: string]: NotificationTemplate } = {
  EmissionInviteTask: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Capitalincrease',
      nb: 'Kapitalforhøyelse',
    },
    code: '',
    data: class {
      constructor(public emissionName: string, public url) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> A new <a href={url} target="_blank">orderform</a> is now avilabel for <b>{emissionName}</b>.</p> ',
      nb: '<p> En ny <a href={url} target="_blank">bestillingsblankett</a> er tilgjengelig for <b>{emissionName}</b>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: ' A new orderform is now avilabel for {emissionName}.',
      nb: ' En ny bestillingsblankett er tilgjengelig for {emissionName}.',
    },
    type: NotificationType.ACTION_AUTO,
  },

  SigningTaskCreatedInviteeSignicat: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Signing Task',
      nb: 'Signeringsoppgave',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public url, public inviterName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> {inviterName} is requesting that you <a href={url} target="_blank">sign</a> the document <b>{documentName}</b>.</p> ',
      nb: '<p>{inviterName} ber deg <a href={url} target="_blank">signere</a> dokumentet <b>{documentName}</b>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: '{inviterName} is requesting that you sign the document {documentName}.',
      nb: '{inviterName} ber deg signere dokumentet {documentName}.',
    },
    type: NotificationType.ACTION_AUTO,
  },

  OrgbrainSupportRequest: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Support Request',
      nb: 'Supportforespørsel',
    },
    code: '',
    data: class {
      constructor(public organizationName: string, public inviterName, public notes) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> {inviterName} is requesting support for {organizationName}.</p> <p>{notes}</p>',
      nb: '<p> {inviterName} ber om support for {organizationName}.</p>  <p>{notes}</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: '{inviterName} is requesting support for {organizationName}. ',
      nb: '{inviterName}  ber om support for {organizationName}.',
    },
    type: NotificationType.ACTION_AUTO,
  },

  SurveyInvite: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Survey Task',
      nb: 'Spørreundersøkelse Oppgave',
    },
    code: '',
    data: class {
      constructor(public surveyName: string, public url, public inviterName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> {inviterName} is requesting that you <a href={url} target="_blank">to do</a> a survey: <b>{surveyName}</b>.</p> ',
      nb: '<p>{inviterName} ber deg <a href={url} target="_blank">gjennomføre</a> en spørreundersøkelse: <b>{surveyName}</b>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: '{inviterName} is requesting that you to do a survey: {surveyName}.',
      nb: '{inviterName} ber deg gjennomføre en spørreundersøkelse: {surveyName}.',
    },
    type: NotificationType.ACTION_AUTO,
  },

  ChatMessageMention: {
    title: {
      // TRANSLATE ChatMessageMention-Title
      en: 'Mentioned in chat',
      nb: 'Nevnt i chat',
    },
    code: '',
    data: class {
      constructor(public inviterName, public url, public meetingName: string) {}
    },
    templates: {
      // TRANSLATE ChatMessageMention-Template
      en: '<p> {inviterName} has mentioned you in a chat in <a href={url}><b>{meetingName}</b></a>.</p> ',
      nb: '<p>{inviterName} har nevnt deg i en chat i <a href={url}><b>{meetingName}</b></a>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE ChatMessageMention-TemplateNoHtml
      en: '{inviterName} has mentioned you in a chat in {meetingName}.',
      nb: '{inviterName} har nevnt deg i en chat i {meetingName}.',
    },
    type: NotificationType.ACTION_AUTO,
  },

  ChatMessageReply: {
    title: {
      // TRANSLATE ChatMessageReply-Title
      en: 'You have a new reply in chat',
      nb: 'Du har et nytt svar i chatten',
    },
    code: '',
    data: class {
      constructor(public inviterName, public url, public meetingName: string) {}
    },
    templates: {
      // TRANSLATE ChatMessageReply-Template
      en: '<p> {inviterName} has replied to you in a chat in <a href={url}><b>{meetingName}</b></a>.</p> ',
      nb: '<p>{inviterName} har svart deg i en chat i <a href={url}><b>{meetingName}</b></a>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE ChatMessageReply-TemplateNoHtml
      en: '{inviterName} has replied to you in a chat in {meetingName}.',
      nb: '{inviterName} har svart deg i en chat i {meetingName}.',
    },
    type: NotificationType.ACTION_AUTO,
  },

  AnnotationPdfMention: {
    title: {
      // TRANSLATE AnnotationPdfMention-Title
      en: 'Mentioned in PDF',
      nb: 'Nevnt i PDF',
    },
    code: '',
    data: class {
      constructor(public inviterName, public url, public pdfName: string) {}
    },
    templates: {
      // TRANSLATE AnnotationPdfMention-Template
      en: '<p> {inviterName} has mentioned you in PDF <a href={url}><b>{pdfName}</b></a>.</p>',
      nb: '<p>{inviterName} har nevnt deg i PDF <a href={url}><b>{pdfName}</b></a>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE AnnotationPdfMention-TemplateNoHtml
      en: '{inviterName} has mentioned you in PDF {pdfName}.',
      nb: '{inviterName} har nevnt deg i PDF {pdfName}.',
    },
    type: NotificationType.ACTION_AUTO,
  },

  SigningTaskCreatedInviteeV2: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Signing Task',
      nb: 'Signeringsoppgave',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public url, public inviterName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> {inviterName} is requesting that you <a href={url} target="_blank">sign</a> the document <b>{documentName}</b>.</p> ',
      nb: '<p>{inviterName} ber deg <a href={url} target="_blank">signere</a> dokumentet <b>{documentName}</b>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: '{inviterName} is requesting that you to sign the document {documentName}.',
      nb: '{inviterName} ber deg signere dokumentet {documentName}.',
    },
    type: NotificationType.ACTION_AUTO,
  },
  SigningTaskCreatedInviter: {
    title: {
      // TRANSLATE SigningTaskCreatedInviterTitle
      en: 'Signing Task Sent',
      nb: 'Signeringsoppgave sendt',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public url, public inviteeName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInviterTemplate
      en: '<p> You invited {inviteeName} to sign the document <b>{documentName}</b>. </p> ',
      nb: '<p> Du inviterte {inviteeName} til å signere dokumentet <b>{documentName}</b>. </p> ',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInviterTemplateNoHtml
      en: 'You invited {inviteeName} to sign the document {documentName}.',
      nb: 'Du inviterte {inviteeName} til å signere dokumentet {documentName}.',
    },
    type: NotificationType.INFORMATION,
  },
  SigningTaskCompletedInviter: {
    title: {
      // TRANSLATE SigningTaskCompletedInviter-title
      en: 'Signing Task Completed',
      nb: 'Signeringsoppgave fullført',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public inviteeName: string) {}
    },
    templates: {
      // TRANSLATE  SigningTaskCompletedInviter-template
      en: '<p> {inviteeName} has signed the document <b>{documentName}</b> </p> ',
      nb: '<p>{inviteeName} har signert dokumentet <b>{documentName}</b></p>',
    },
    templatesNoHtml: {
      // TRANSLATE  SigningTaskCompletedInviter-TemplateNoHtml
      en: '{inviteeName} has signed the document {documentName}.',
      nb: '{inviteeName} har signert dokumentet {documentName}.',
    },
    type: NotificationType.INFORMATION,
  },
  SigningTaskCompletedInvitee: {
    title: {
      // TRANSLATE SigningTaskCompletedInvitee-title
      en: 'Signing Task Completed',
      nb: 'Signeringsoppgave fullført',
    },
    code: '',
    data: class {
      constructor(public documentName: string) {}
    },
    templates: {
      // TRANSLATE SigningTaskCompletedInvitee-template
      en: '<p>  You have signed the document <b>{documentName}</b> </p> ',
      nb: '<p> Du har signert dokumentet <b>{documentName}</b></p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCompletedInvitee-TemplateNoHtml
      en: 'You have signed the document {documentName}.',
      nb: 'Du har signert dokumentet {documentName}.',
    },
    type: NotificationType.INFORMATION,
  },
  SigningTaskRejectedInviter: {
    title: {
      // TRANSLATE SigningTaskRejectedInviter-title
      en: 'Signing Task Rejected',
      nb: 'Signeringsoppgave avvist',
    },
    code: '',
    data: class {
      constructor(public url: string, public documentName: string, public signerName: string) {}
    },
    templates: {
      // TRANSLATE SigningTaskRejectedInviter-template
      en: '<p> {inviteeName} has rejected to sign the document <b>{documentName}</b> </p> ',
      nb: '<p>{inviteeName} har avvist å signere dokumentet <b>{documentName}</b></p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskRejectedInviter-TemplateNoHtml
      en: '{inviteeName} has rejected to sign the document {documentName}.',
      nb: '{inviteeName} har avvist å signere dokumentet {documentName}.',
    },
    type: NotificationType.INFORMATION,
  },
  EmailFailedToSend: {
    title: {
      // TRANSLATE EmailFailedToSend-title
      en: 'Email delivery failed',
      nb: 'Levering av e-post mislyktes',
    },
    code: '',
    data: class {
      constructor(public address: string, public topic: string) {}
    },
    templates: {
      // TRANSLATE EmailFailedToSend-template
      en: '<p>Email {topic} has not been delivered to <b>{address}</b></p>',
      nb: '<p>E-post {topic} er ikke levert til <b>{address}</b></p>',
    },
    templatesNoHtml: {
      // TRANSLATE EmailFailedToSend-TemplateNoHtml
      en: 'Email {topic} has not been delivered to {address}.',
      nb: 'E-post {topic} er ikke levert til {address}.',
    },
    type: NotificationType.INFORMATION,
  },
  NewsNotification: {
    title: {
      // TRANSLATE NewsNotification-title
      en: 'News update',
      nb: 'Nyhetsoppdatering',
    },
    code: '',
    data: class {
      constructor(public topic, public url: string) {}
    },
    templates: {
      // TRANSLATE NewsNotification-Template
      en: '<p> {topic}. To read more <a href={url} target="_blank">click<a> </p> ',
      nb: '<p>{topic}. For å lese mer <a href={url} target="_blank">klikk<a></p></a>',
    },
    templatesNoHtml: {
      // TRANSLATE NewsNotification-TemplateNoHtml
      en: '{topic}. To read more click.',
      nb: '{topic}. For å lese mer klikk.',
    },
    type: NotificationType.ACTION_AUTO,
  },
  SigningOrderCreated: {
    title: {
      // TRANSLATE  SigningOrderCreated-title
      en: 'Signing Request Created',
      nb: 'Signeringsordre opprettet',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public url: string) {}
    },
    templates: {
      // TRANSLATE  SigningOrderCreated-template
      en: '<p>A signing request for <b>{documentName}</b> has been created</p> ',
      nb: '<p>Det er opprettet en signeringsordre for <b>{documentName}</b></p> ',
    },
    templatesNoHtml: {
      // TRANSLATE  SigningOrderCreated-TemplateNoHtml
      en: 'A signing request for {documentName} has been created.',
      nb: 'Det er opprettet en signeringsordre for {documentName}.',
    },
    type: NotificationType.INFORMATION,
  },
  SigningOrderCompletedInviter: {
    title: {
      // TRANSLATE SigningOrderCompletedInviter-title
      en: 'Signing Request Completed',
      nb: 'Signeringsordre fullført',
    },
    code: '',
    data: class {
      constructor(public documentName: string) {}
    },
    templates: {
      // TRANSLATE SigningOrderCompletedInviter-template
      en: '<p>A signing order for <b>{documentName}</b> has completed </p> ',
      nb: '<p>En signeringsordre for <b>{documentName}</b> er fullført </p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningOrderCompletedInviter-TemplateNoHtml
      en: 'A signing order for {documentName} has completed.',
      nb: 'En signeringsordre for {documentName} er fullført.',
    },
    type: NotificationType.INFORMATION,
  },
  SigningOrderCreateFailed: {
    title: {
      // TRANSLATE SigningOrderCreateFailed-title
      en: 'Signing Request Failed to Create',
      nb: 'Signeringsordre kan ikke opprettes',
    },
    code: '',
    data: class {
      documentName: string;
    },
    templates: {
      // TRANSLATE SigningOrderCreateFailed-template
      en: '<p>There was a system problem trying to create a signing request for <b>{documentName}</b></p>',
      nb: '<p>En signeringsordre for <b>{documentName}</b> kan ikke opprettes</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningOrderCreateFailed-TemplateNoHtml
      en: 'There was a system problem trying to create a signing request for {documentName}.',
      nb: 'En signeringsordre for {documentName} kan ikke opprettes.',
    },
    type: NotificationType.INFORMATION,
  },
  SigningOrderExpiredInviter: {
    title: {
      // TRANSLATE  SigningOrderExpiredInviter-title
      en: 'Signing Request Expired',
      nb: 'Signeringsordre utløpt',
    },
    code: '',
    data: class {
      constructor(public documentName: string) {}
    },
    templates: {
      // TRANSLATE SigningOrderExpiredInviter-template
      en: '<p>A signing request for <b>{documentName}</b> expired</p> ',
      nb: '<p>En signeringsordre for <b>{documentName}</b> utløpt</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningOrderExpiredInviter-TemplateNoHtml
      en: 'A signing request for {documentName} expired.',
      nb: 'En signeringsordre for {documentName} utløpt.',
    },
    type: NotificationType.INFORMATION,
  },
  // TODO
  MeetingInvitation: {
    title: {
      en: 'Meeting Invitation',
      nb: '',
    },
    data: class {
      constructor() {}
    },
    code: '',
    type: NotificationType.INFORMATION,
  },
  MeetingAttendenceReminder: {
    title: {
      en: 'Minutes Reminder',
      nb: '',
    },
    code: '',
    data: class {
      constructor() {}
    },
    type: NotificationType.INFORMATION,
  },
  MinutesReview: {
    title: {
      en: 'Minutes Review',
      nb: '',
    },
    code: '',
    data: class {
      constructor() {}
    },
    type: NotificationType.INFORMATION,
  },
  MeetingOrganizerReminderToInvite: {
    title: {
      en: 'Meeting Organizer Reminder To Invite',
      nb: '',
    },
    code: '',
    data: class {
      constructor() {}
    },
    type: NotificationType.INFORMATION,
  },

  ResponsibleForActionPoint: {
    title: {
      en: 'New task created',
      nb: 'Ny oppgave lagt til',
    },
    code: '',
    data: class {
      title: string;
      url: string;
    },
    templates: {
      en: '<p>A task that you are responsible for: <b><a href="{url}">{title}</a></b> has been created</p> ',
      nb: '<p>En oppgave du er ansvarlig for: <b><a href="{url}">{title}</a></b> har blitt lagt til</p>',
    },
    templatesNoHtml: {
      en: 'A task that you are responsible for: {title} has been created.',
      nb: 'En oppgave du er ansvarlig for: {title} har blitt lagt til.',
    },
    type: NotificationType.ACTION_AUTO,
  },

  InviteForMeetingTime: {
    title: {
      en: textMappings.InvitationToVoteForMeetingTime.en,
      nb: textMappings.InvitationToVoteForMeetingTime.nb,
    },
    code: '',
    data: class {
      title: string;
      url: string;
    },
    templates: {
      en: `<p><b><a href="{url}">${textMappings.VoteTimeButtonText.en}</a></b> {title}</p>`,
      nb: `<p><b><a href="{url}">${textMappings.VoteTimeButtonText.nb}</a></b> {title}</p>`,
    },
    templatesNoHtml: {
      en: `${textMappings.VoteTimeButtonText.en} {title}`,
      nb: `${textMappings.VoteTimeButtonText.nb} {title}`,
    },
    type: NotificationType.ACTION_AUTO,
  },
};

// Well this is naff but it helps checking.
const keys = Object.keys(notificationCodes);
for (const key of keys) {
  notificationCodes[key].code = key;
}
// This should match the notificationSchema  (lib-mongoose)
// LINK  ../../../../libs/lib-mongoose/src/lib/notification-schema.ts#NOTIFICATION_SCHEMA
// ANCHOR[id=NOTIFICATION_CLASS]
export class Notification {
  id: string;
  due: Date; //  time stamp for when tasks is due  or just the date to order in the list.
  userId: string; // userId of who we are notifying
  code: string; //   Unique code for this Notifiction type -- used for language templates -- decoding data
  data: string; //  Stringified  artbirtary data specific to  code
  status: NotificationStatus; //
  expiresAfter: number; // unix time diff relative to due date
  activiateBefore: number;
  deleted: boolean; //  soft delete flag
  // OPTIONAL KEYS FOR SEARCHING  (keep adding as needed)
  organizationId: string; //  organization context
  meetingId: string; // meeting context
  direntId: string; //  for file operations.
  signOrderId: string; // sign orders  mongodb record id
  requestId: String; //  signicat reference for order
  taskId: String; //  reference for a signing task
  createdAt: Date; // mongodb  auto created
  updatedAt: Date;
}

export enum WebPushNotificationType {
  SIGNING_REQUEST = 'signing_request',
  DOCUMENT_SIGNED = 'document_signed',
  MEETING_SCHEDULED = 'meeting_scheduled',
  MEETING_STARTED = 'meeting_started',
  MEETING_ABOUT_TO_START = 'meeting_to_start',
}
