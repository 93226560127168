export enum CompanyAcronymsEnum {
  'AAFY' = 'AAFY',
  'ADOS' = 'ADOS',
  'ANNA' = 'ANNA',
  'ANS' = 'ANS',
  'AS' = 'AS',
  'ASA' = 'ASA',
  'BA' = 'BA',
  'BBL' = 'BBL',
  'BEDR' = 'BEDR',
  'BO' = 'BO',
  'BRL' = 'BRL',
  'DA' = 'DA',
  'ENK' = 'ENK',
  'EØFG' = 'EØFG',
  'ESEK' = 'ESEK',
  'FKF' = 'FKF',
  'FLI' = 'FLI',
  'NIF' = 'NIF',
  'FYLK' = 'FYLK',
  'GFS' = 'GFS',
  'IKJP' = 'IKJP',
  'IKS' = 'IKS',
  'SBSTI' = 'SBSTI',
  'KBO' = 'KBO',
  'KF' = 'KF',
  'KIRK' = 'KIRK',
  'KOMM' = 'KOMM',
  'KS' = 'KS',
  'KTRF' = 'KTRF',
  'NUF' = 'NUF',
  'OPMV' = 'OPMV',
  'ORGL' = 'ORGL',
  'PERS' = 'PERS',
  'PK' = 'PK',
  'PRE' = 'PRE',
  'SA' = 'SA',
  'SAM' = 'SAM',
  'SE' = 'SE',
  'SF' = 'SF',
  'SPA' = 'SPA',
  'STI' = 'STI',
  'STAT' = 'STAT',
  'SÆR' = 'SÆR',
  'TVAM' = 'TVAM',
  'VPFO' = 'VPFO',
  'SUBGR' = 'SUBGR',
  'SUBGR-GENERAL' = 'SUBGR-GENERAL',
  'SUBGR-STYR' = 'SUBGR-STYR',
  'SUBGR-LEDER' = 'SUBGR-LEDER',
  'SUBGR-VALGKOMITE' = 'SUBGR-VALGKOMITE',
  'SUBGR-RENUM' = 'SUBGR-RENUM',
  'SUBGR-KONTROL' = 'SUBGR-KONTROL',
  'SUBGR-REVISJON' = 'SUBGR-REVISJON',
  'SUBGR-PROJECT' = 'SUBGR-PROJECT',
  'SUBGR-DD' = 'SUBGR-DD',
  'SUBGR-WORKGROUP' = 'SUBGR-WORKGROUP',
}

// These are used for the organizational differences in terminology
export enum CompanyTerminologyEnum {
  'default' = 'default',
  'fliAlike' = 'fliAlike',
  'BRL' = 'BRL',
  'ESEK' = 'ESEK',
  'AS' = 'AS',
  'SUBGR-DD' = 'SUBGR-DD',
  'SUBGR' = 'SUBGR',
}

// export function getPackTypeHelper(orgType) {
//   orgType = orgType || '';
//   switch (orgType) {
//     case 'FLI':
//     case 'SA':
//     case 'BA':
//     case 'STI':
//     case 'FKF':
//     case 'KF':
//     case 'IKS':
//     case 'ANS':
//     case 'DA':
//       return 'fliAlike';
//     case 'BRL':
//       return 'BRL';
//     case 'ESEK':
//       return 'ESEK';
//     case 'AS':
//       return 'AS';
//     case 'SUBGR-DD':
//       return 'SUBGR-DD';
//     default:
//       if (orgType.startsWith('SUBGR-')) {
//         return 'SUBGR';
//       } else {
//         return 'default';
//       }
//   }
// }
// }

export type CompanyAcronymsMap = {
  [type in CompanyAcronymsEnum]: {
    nb: string;
    en: string;
    sv?: string;
    terminolgy?: CompanyTerminologyEnum;
  };
};

export const companyAcronymsMap: CompanyAcronymsMap = {
  [CompanyAcronymsEnum.AAFY]: {
    nb: 'Ikke næringsdrivende virksomhet',
    en: 'No business activity',
  },
  [CompanyAcronymsEnum.ADOS]: {
    nb: 'Administrativ enhet - offentlig sektor',
    en: 'Administrative entity - Public sector',
  },
  [CompanyAcronymsEnum.ANNA]: {
    nb: 'Annen juridisk person',
    en: 'Other body corporate',
  },
  [CompanyAcronymsEnum.ANS]: {
    nb: 'Ansvarlig selskap med solidarisk ansvar',
    en: 'General Partnership',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.AS]: {
    nb: 'Aksjeselskap',
    en: 'Limited company',
    terminolgy: CompanyTerminologyEnum.AS,
  },
  [CompanyAcronymsEnum.ASA]: {
    nb: 'Allmennaksjeselskap',
    en: 'Public limited company',
  },
  [CompanyAcronymsEnum.BA]: {
    nb: 'Selskap med begrenset ansvar',
    en: 'Company with limited liability',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.BBL]: {
    nb: 'Boligbyggelag',
    en: 'House building cooperative',
  },
  [CompanyAcronymsEnum.BEDR]: {
    nb: 'Bedrift',
    en: 'Enterprise',
  },
  [CompanyAcronymsEnum.BO]: {
    nb: 'Andre bo',
    en: 'Other estates',
  },
  [CompanyAcronymsEnum.BRL]: {
    nb: 'Borettslag',
    en: 'Housing cooperative',
    terminolgy: CompanyTerminologyEnum.BRL,
  },
  [CompanyAcronymsEnum.DA]: {
    nb: 'Ansvarlig selskap med delt ansvar',
    en: 'General Partnership with shared liability',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.ENK]: {
    nb: 'Enkeltpersonforetak',
    en: 'Sole proprietorship',
  },
  [CompanyAcronymsEnum.EØFG]: {
    nb: 'Europeisk økonomisk foretaksgruppe',
    en: 'European Economic Enterprise Group',
  },
  [CompanyAcronymsEnum.ESEK]: {
    nb: 'Eierseksjonssameie',
    en: 'Condominium flat owner',
    terminolgy: CompanyTerminologyEnum.ESEK,
  },
  [CompanyAcronymsEnum.FKF]: {
    nb: 'Fylkeskommunalt foretak',
    en: 'County municipal business enterprise',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.FLI]: {
    nb: 'Forening/lag/innretning',
    en: 'Association/club/organization',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.NIF]: {
    nb: 'Forening/lag/innretning',
    en: 'Association/club/organization',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.FYLK]: {
    nb: 'Fylkeskommune',
    en: 'County municipality',
  },
  [CompanyAcronymsEnum.GFS]: {
    nb: 'Gjensidig forsikringsselskap',
    en: 'Mutual insurance company',
  },
  [CompanyAcronymsEnum.IKJP]: {
    nb: 'Andre ikke-juridiske personer',
    en: 'Other non-body corporate',
  },
  [CompanyAcronymsEnum.IKS]: {
    nb: 'Interkommunalt selskap',
    en: 'Inter-municipal company',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.SBSTI]: {
    nb: 'Stiftelse',
    en: 'Foundation',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.KBO]: {
    nb: 'Konkursbo',
    en: 'Bankrupt estate',
  },
  [CompanyAcronymsEnum.KF]: {
    nb: 'Kommunalt foretak',
    en: 'Municipal business enterprise',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.KIRK]: {
    nb: 'Den norske kirke',
    en: 'The Church of Norway',
  },
  [CompanyAcronymsEnum.KOMM]: {
    nb: 'Kommune',
    en: 'Municipality',
  },
  [CompanyAcronymsEnum.KS]: {
    nb: 'Kommandittselskap',
    en: 'Limited partnership',
  },
  [CompanyAcronymsEnum.KTRF]: {
    nb: 'Kontorfellesskap',
    en: 'Joint office',
  },
  [CompanyAcronymsEnum.NUF]: {
    nb: 'Norskregistrert utenlandsk foretak',
    en: 'Norwegian registered foreign business',
  },
  [CompanyAcronymsEnum.OPMV]: {
    nb: 'Særskilt oppdelt enhet, jf. mval. § 2-2',
    en: 'Specially sectioned unit',
  },
  [CompanyAcronymsEnum.ORGL]: {
    nb: 'Organisasjonsledd',
    en: 'Organizational section',
  },
  [CompanyAcronymsEnum.PERS]: {
    nb: 'Andre enkeltpersoner som registreres i tilknyttet register',
    en: 'Other individuals registered in affiliated register',
  },
  [CompanyAcronymsEnum.PK]: {
    nb: 'Pensjonskasse',
    en: 'Pension fund',
  },
  [CompanyAcronymsEnum.PRE]: {
    nb: 'Partrederi',
    en: 'Jointly owned shipping company',
  },
  [CompanyAcronymsEnum.SA]: {
    nb: 'Samvirkeforetak',
    en: 'Co-operative',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.SAM]: {
    nb: 'Tingsrettslig sameie',
    en: 'Joint ownership according to the law of property',
  },
  [CompanyAcronymsEnum.SE]: {
    nb: 'Europeisk selskap',
    en: 'European company',
  },
  [CompanyAcronymsEnum.SF]: {
    nb: 'Statsforetak',
    en: 'Public corporation',
  },
  [CompanyAcronymsEnum.SPA]: {
    nb: 'Sparebank',
    en: 'Savings bank',
  },
  [CompanyAcronymsEnum.STI]: {
    nb: 'Stiftelse',
    en: 'Foundation',
    terminolgy: CompanyTerminologyEnum.fliAlike,
  },
  [CompanyAcronymsEnum.STAT]: {
    nb: 'Staten',
    en: 'State',
  },
  [CompanyAcronymsEnum.SÆR]: {
    nb: 'Annet foretak ifølge særskilt lov',
    en: 'Other business enterprise in accordance with special legislation',
  },
  [CompanyAcronymsEnum.TVAM]: {
    nb: 'Tvangsregistrert for MVA',
    en: 'Compulsory registration in the Value Added Tax Register',
  },
  [CompanyAcronymsEnum.VPFO]: {
    nb: 'Verdipapirfond',
    en: 'Unit trusts',
  },
  [CompanyAcronymsEnum['SUBGR-GENERAL']]: {
    nb: 'Generell gruppe',
    en: 'General group',
    sv: 'Allmän grupp',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
  [CompanyAcronymsEnum['SUBGR-STYR']]: {
    nb: 'Styringsgruppe',
    en: 'Steering group',
    sv: 'Styrgrupp',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
  [CompanyAcronymsEnum['SUBGR-LEDER']]: {
    nb: 'Ledergruppe',
    en: 'Management group',
    sv: 'Ledningsgrupp',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
  [CompanyAcronymsEnum['SUBGR-VALGKOMITE']]: {
    nb: 'Valgkomite',
    en: 'Nomination committee',
    sv: 'Valberedning',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
  [CompanyAcronymsEnum['SUBGR-RENUM']]: {
    nb: 'Godtgjørelsesutvalg',
    en: 'Remuneration committee',
    sv: 'Ersättningskommitté',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
  [CompanyAcronymsEnum['SUBGR-KONTROL']]: {
    nb: 'Kontrollutvalg',
    en: 'Control committee',
    sv: 'Kontrollkommitté',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
  [CompanyAcronymsEnum['SUBGR-REVISJON']]: {
    nb: 'Revisjonsutvalg',
    en: 'Audit committee',
    sv: 'Revisionskommitté',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
  [CompanyAcronymsEnum['SUBGR-PROJECT']]: {
    nb: 'Prosjektgruppe',
    en: 'Project group',
    sv: 'Projektgrupp',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
  [CompanyAcronymsEnum['SUBGR-WORKGROUP']]: {
    nb: 'Arbeidsgruppe',
    en: 'Working group',
    sv: 'Arbetsgrupp',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
  [CompanyAcronymsEnum['SUBGR-DD']]: {
    nb: 'Due diligence',
    en: 'Due diligence',
    sv: 'Due diligence',
    terminolgy: CompanyTerminologyEnum['SUBGR-DD'],
  },
  [CompanyAcronymsEnum['SUBGR']]: {
    nb: 'Legacy subgroup',
    en: 'Legacy subgroup',
    sv: 'Legacy subgroup',
    terminolgy: CompanyTerminologyEnum.SUBGR,
  },
};

for (const key of Object.keys(CompanyAcronymsEnum)) {
  if (!companyAcronymsMap[key].terminolgy) {
    companyAcronymsMap[key].terminolgy = CompanyTerminologyEnum.default;
  }
}
