/* eslint-disable @typescript-eslint/no-explicit-any */
// Add permission and roles for compoany types here
//  See docs/Reference/PackagesRolesPemermissions.md

import { CompanyAcronymsEnum } from './companyTypesWithTypeChecking';
import {
  DD_ONLY_PERMISSIONS,
  DD_ONLY_ROLES,
  FULL_PERMISSIONS,
  FULL_ROLES,
  OptionalPermissionBooleanMap,
  OptionalRoleBooleanMap,
  PermissionBooleanMap,
  RoleBooleanMap,
} from './rolesAndPermissions';

export type PermissionsAndRoles = {
  permissions: PermissionBooleanMap;
  roles: RoleBooleanMap;
};

// These are used to filter ALL subgroups.
// Additional filtering is done for each company type.

export enum CompanyMetaTypeEnum {
  DEFAULT_ORGANIZATION = 'DEFAULT_ORGANIZATION',
  NO_OWNERS_ORGANIZATION = 'NO_OWNERS_ORGANIZATION',
  FULL_SUBGROUP = 'FULL_SUBGROUP',
  DD_SUBGROUP = 'DD_SUBGROUP',
}

export interface CompanyMetaTypeInfoRecord {
  names: CompanyAcronymsEnum[];
  permissions: PermissionBooleanMap;
  roles: RoleBooleanMap;
}

export type CompanyMetaTypeInfoRecordMap = {
  [type in CompanyMetaTypeEnum]: CompanyMetaTypeInfoRecord;
};

export const _companyMetaTypeInfoRecordMap: CompanyMetaTypeInfoRecordMap = {
  DEFAULT_ORGANIZATION: {
    names: [
      CompanyAcronymsEnum.AS,
      CompanyAcronymsEnum.AAFY,
      CompanyAcronymsEnum.ADOS,
      CompanyAcronymsEnum.ANNA,
      CompanyAcronymsEnum.ANS,
      CompanyAcronymsEnum.ASA,
      CompanyAcronymsEnum.BA,
      CompanyAcronymsEnum.BBL,
      CompanyAcronymsEnum.BEDR,
      CompanyAcronymsEnum.BO,
      CompanyAcronymsEnum.BRL,
      CompanyAcronymsEnum.DA,
      CompanyAcronymsEnum.ENK,
      CompanyAcronymsEnum.EØFG,
      CompanyAcronymsEnum.ESEK,
      CompanyAcronymsEnum.FKF,
      CompanyAcronymsEnum.FLI,
      CompanyAcronymsEnum.NIF,
      CompanyAcronymsEnum.FYLK,
      CompanyAcronymsEnum.GFS,
      CompanyAcronymsEnum.IKJP,
      CompanyAcronymsEnum.KBO,
      CompanyAcronymsEnum.KF,
      CompanyAcronymsEnum.KIRK,
      CompanyAcronymsEnum.KOMM,
      CompanyAcronymsEnum.KS,
      CompanyAcronymsEnum.KTRF,
      CompanyAcronymsEnum.NUF,
      CompanyAcronymsEnum.OPMV,
      CompanyAcronymsEnum.ORGL,
      CompanyAcronymsEnum.PERS,
      CompanyAcronymsEnum.PK,
      CompanyAcronymsEnum.PRE,
      CompanyAcronymsEnum.SA,
      CompanyAcronymsEnum.SAM,
      CompanyAcronymsEnum.SE,
      CompanyAcronymsEnum.SF,
      CompanyAcronymsEnum.SPA,
      CompanyAcronymsEnum.STAT,
      CompanyAcronymsEnum.SÆR,
      CompanyAcronymsEnum.TVAM,
      CompanyAcronymsEnum.VPFO,
    ],
    permissions: removePermissions(FULL_PERMISSIONS, DD_ONLY_PERMISSIONS),
    roles: removeRoles(FULL_ROLES, DD_ONLY_ROLES),
  },

  NO_OWNERS_ORGANIZATION: {
    names: [CompanyAcronymsEnum.STI, CompanyAcronymsEnum.IKS],
    roles: {
      CEO: true,
      CHAIR: true,
      DEPUTYCHAIR: true,
      BOARDMEMBER: true,
      DEPUTYMEMBER: true,
      OBSERVER: true,
      BOARDSECRETARY: true,
      AUDITOR: true,
      ADMIN: true,
      USER_ADMIN: true,
      SCHOOL: true,
      ORGBRAIN_SUPPORT: true,

      SHAREHOLDER: false,

      CONTACTPERSON: false,
      SHAREHOLDER_PROXY: false,
      BUYER: false,

      OWNER: false,
      SELLER: false,
      SELLER_ADMIN: false,
      BUYER_ADMIN: false,
      ROLE_1: false,
      ROLE_2: false,
      ROLE_3: false,
      ROLE_4: false,
      ROLE_5: false,
      ROLE_6: false,
    },
    permissions: {
      ADMIN: true,
      NEWS_ADMIN: true,
      GA_NEWS_ADMIN: true,
      MEETING_ADMIN: true,
      BOARD_MEETING_ADMIN: true,
      USERMANAGER: true,
      ACCESS_TO_BOARD_MEETINGS: true,
      CAN_VOTE: true,
      CAN_VOTE_BOARDMEETING: true,
      VIEW_SHARES: true,
      SEE_USER_EMAILS: true,
      SEE_MANAGE_SIGNATURES: true,
      SEE_USER_NAMES: true,
      ACCESS_TO_NEWS_FOR_BOARD: true,
      SEE_CYCLIC_CALENDAR: true,
      CREATE_SIGNING_ORDER: true,
      DATAROOM_DOWNLOAD_ZIP: true,
      ORGBRAIN_ACADEMY: true,
      DATAROOM_ADMIN: true,
      DATAROOM_SEE_SHOW_DELETED: true,
      DATAROOM_COPY_FILE: true,
      DATAROOM_SEE_FILE_DETAILS: true,
      DATAROOM_SEE_FILE_HISTORY: true,
      DATAROOM_CREATE_TASK_FROM_FILE: true,
      DATAROOM_SEE_FILE_MENU: true,
      DATAROOM_SET_FILE_STATUS: true,
      TEMPLATES_ADMIN: true,
      meetings: true,
      SEE_FOLLOWUP_CASES: true,
      SEE_ACTION_POINTS: true,
      TASKS_ADMIN: true,
      tasks: true,
      chat: true,
      news: true,
      files: true,
      school: true,
      templates: true,
      surveys: true,
      organization: true,
      OPENAI_USER: true,
      GA_MEETING_ADMIN: false,
      ACCESS_TO_GA_MEETINGS: false,
      SHAREHOLDER: false,
      OWNER: false,
      BUYER: false,
      SELLER: false,
      SELLER_ADMIN: false,
      BUYER_ADMIN: false,
      ACCESS_TO_NEWS_FOR_SHAREHOLDERS: false,
      shareholders: false,
      SHAREHOLDER_ADMIN: false,
      SEE_ENTITY_SENSITIVE: false,
    },
  },

  FULL_SUBGROUP: {
    names: [
      CompanyAcronymsEnum['SUBGR'],
      CompanyAcronymsEnum['SUBGR-GENERAL'],
      CompanyAcronymsEnum['SUBGR-STYR'],
      CompanyAcronymsEnum['SUBGR-LEDER'],
      CompanyAcronymsEnum['SUBGR-VALGKOMITE'],
      CompanyAcronymsEnum['SUBGR-RENUM'],
      CompanyAcronymsEnum['SUBGR-KONTROL'],
      CompanyAcronymsEnum['SUBGR-REVISJON'],
      CompanyAcronymsEnum['SUBGR-PROJECT'],
      CompanyAcronymsEnum['SUBGR-WORKGROUP'],
    ],
    roles: {
      CHAIR: true,
      DEPUTYCHAIR: true,
      BOARDMEMBER: true,
      OBSERVER: true,
      ADMIN: true,
      USER_ADMIN: true,
      ORGBRAIN_SUPPORT: true,
      CEO: false,
      BOARDSECRETARY: false,

      DEPUTYMEMBER: false,

      SHAREHOLDER: false,
      AUDITOR: false,
      CONTACTPERSON: false,
      SHAREHOLDER_PROXY: false,
      BUYER: false,
      SCHOOL: false,
      OWNER: false,
      SELLER: false,
      SELLER_ADMIN: false,
      BUYER_ADMIN: false,
      ROLE_1: false,
      ROLE_2: false,
      ROLE_3: false,
      ROLE_4: false,
      ROLE_5: false,
      ROLE_6: false,
    },
    permissions: {
      ADMIN: true,
      MEETING_ADMIN: true,
      BOARD_MEETING_ADMIN: true,
      DATAROOM_ADMIN: true,
      USERMANAGER: true,
      CAN_VOTE: true,
      CAN_VOTE_BOARDMEETING: true,
      ACCESS_TO_BOARD_MEETINGS: true,
      VIEW_SHARES: true,
      CREATE_SIGNING_ORDER: true,
      SEE_CYCLIC_CALENDAR: true,
      SEE_USER_EMAILS: true,
      SEE_MANAGE_SIGNATURES: true,
      SEE_USER_NAMES: true,
      DATAROOM_DOWNLOAD_ZIP: true,
      ORGBRAIN_ACADEMY: true,
      DATAROOM_SEE_SHOW_DELETED: true,
      DATAROOM_COPY_FILE: true,
      DATAROOM_SEE_FILE_DETAILS: true,
      DATAROOM_SEE_FILE_HISTORY: true,
      DATAROOM_CREATE_TASK_FROM_FILE: true,
      DATAROOM_SEE_FILE_MENU: true,
      DATAROOM_SET_FILE_STATUS: true,
      TEMPLATES_ADMIN: true,
      meetings: true,
      templates: true,
      files: true,
      SEE_FOLLOWUP_CASES: true,
      SEE_ACTION_POINTS: true,
      TASKS_ADMIN: true,
      tasks: true,
      chat: true,
      school: true,
      surveys: true,
      news: true,
      NEWS_ADMIN: true,
      GA_NEWS_ADMIN: true,
      organization: true,
      OPENAI_USER: true,
      GA_MEETING_ADMIN: false,
      ACCESS_TO_GA_MEETINGS: false,
      SHAREHOLDER: false,
      OWNER: false,
      BUYER: false,
      SELLER: false,
      SELLER_ADMIN: false,
      BUYER_ADMIN: false,
      ACCESS_TO_NEWS_FOR_SHAREHOLDERS: false,
      ACCESS_TO_NEWS_FOR_BOARD: false,
      shareholders: false,
      SHAREHOLDER_ADMIN: false,
      SEE_ENTITY_SENSITIVE: false,
    },
  },

  DD_SUBGROUP: {
    names: [CompanyAcronymsEnum['SUBGR-DD']],
    roles: {
      USER_ADMIN: true,
      BUYER: true,
      SELLER: true,
      BUYER_ADMIN: true,
      SELLER_ADMIN: true,
      ADMIN: true,
      ORGBRAIN_SUPPORT: true,
      CHAIR: false,
      BOARDMEMBER: false,
      CEO: false,
      BOARDSECRETARY: false,
      DEPUTYCHAIR: false,
      DEPUTYMEMBER: false,
      OBSERVER: false,
      SHAREHOLDER: false,
      AUDITOR: false,
      CONTACTPERSON: false,
      SHAREHOLDER_PROXY: false,

      SCHOOL: false,
      OWNER: false,
      ROLE_1: false,
      ROLE_2: false,
      ROLE_3: false,
      ROLE_4: false,
      ROLE_5: false,
      ROLE_6: false,
    },
    permissions: {
      ADMIN: true,
      MEETING_ADMIN: true,
      DATAROOM_ADMIN: true,
      BOARD_MEETING_ADMIN: true,
      USERMANAGER: true,
      ACCESS_TO_BOARD_MEETINGS: true,
      CAN_VOTE: true,
      VIEW_SHARES: true,
      BUYER: true,
      SELLER: true,
      BUYER_ADMIN: true,
      SELLER_ADMIN: true,
      SEE_USER_EMAILS: true,
      CREATE_SIGNING_ORDER: true,
      SEE_MANAGE_SIGNATURES: true,
      SEE_USER_NAMES: true,
      DATAROOM_DOWNLOAD_ZIP: true,
      ORGBRAIN_ACADEMY: true,
      DATAROOM_SEE_SHOW_DELETED: true,
      DATAROOM_COPY_FILE: true,
      DATAROOM_SEE_FILE_DETAILS: true,
      DATAROOM_SEE_FILE_HISTORY: true,
      DATAROOM_CREATE_TASK_FROM_FILE: true,
      DATAROOM_SEE_FILE_MENU: true,
      DATAROOM_SET_FILE_STATUS: true,
      TEMPLATES_ADMIN: true,
      SEE_FOLLOWUP_CASES: true,
      SEE_ACTION_POINTS: true,
      TASKS_ADMIN: true,
      OPENAI_USER: true,
      tasks: true,
      chat: true,
      files: true,
      meetings: true,
      organization: true,

      GA_MEETING_ADMIN: false,
      CAN_VOTE_BOARDMEETING: false,
      ACCESS_TO_GA_MEETINGS: false,
      SHAREHOLDER: false,
      OWNER: false,
      SEE_CYCLIC_CALENDAR: false,
      ACCESS_TO_NEWS_FOR_SHAREHOLDERS: false,
      ACCESS_TO_NEWS_FOR_BOARD: false,
      shareholders: false,
      SHAREHOLDER_ADMIN: false,
      SEE_ENTITY_SENSITIVE: false,
      templates: false,
      school: false,
      surveys: false,
      news: false,
      NEWS_ADMIN: false,
      GA_NEWS_ADMIN: false,
    },
  },
};

const _companyAcronymToPermissionsAndRolesMap: {
  [type in CompanyAcronymsEnum]?: PermissionsAndRoles;
} = {};

for (const type of Object.keys(CompanyMetaTypeEnum)) {
  const record: CompanyMetaTypeInfoRecord = _companyMetaTypeInfoRecordMap[type];
  removeFalseyValues(record.roles);
  removeFalseyValues(record.permissions);
}

for (const key of Object.keys(_companyMetaTypeInfoRecordMap)) {
  const record: CompanyMetaTypeInfoRecord = _companyMetaTypeInfoRecordMap[key];

  for (const name of record.names) {
    _companyAcronymToPermissionsAndRolesMap[name] = {
      roles: record.roles,
      permissions: record.permissions,
    };
  }
}

export function getCompanyAcronymToPermissionsAndRoles(anyOldStringThatYouWantToUse: string) {
  let record = _companyAcronymToPermissionsAndRolesMap[anyOldStringThatYouWantToUse];
  if (!record) {
    record = _companyAcronymToPermissionsAndRolesMap[CompanyAcronymsEnum.AS];
  }
  return record;
}

export function removeRoles(
  roles: OptionalRoleBooleanMap,
  toRemove: OptionalRoleBooleanMap
): RoleBooleanMap {
  const newRoleMap: RoleBooleanMap = {} as RoleBooleanMap;

  for (const key of Object.keys(roles)) {
    if (!toRemove[key]) {
      newRoleMap[key] = true;
    }
  }
  return newRoleMap;
}

function removePermissions(
  perms: OptionalPermissionBooleanMap,
  toRemove: OptionalPermissionBooleanMap
): PermissionBooleanMap {
  const newPermsisionMap: PermissionBooleanMap = {} as PermissionBooleanMap;

  for (const key of Object.keys(perms)) {
    if (!toRemove[key]) {
      newPermsisionMap[key] = true;
    }
  }
  return newPermsisionMap;
}

function removeFalseyValues(map: any) {
  for (const key of Object.keys(map)) {
    if (!map[key]) {
      delete map[key];
    }
  }
}

// console.log(JSON.stringify(companyTypeToInfoRecord, null, 2));
