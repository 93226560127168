import { monitorUrls } from './monitorService';

import { sharedenvironment } from './shared';
import { frontendNamesStaging, serverConfigsStaging } from './systemNamesAndUrls';

const APP_BASE_URL = 'https://preprod2.staging.orgbrain.ai/';

const myenvironment = {
  production: true,

  frontendList: frontendNamesStaging,
  name: 'PREPROD',
  logServiceUrl: monitorUrls.preprod.url, // DEPreCAte ME
  defaultServerConfig: serverConfigsStaging,
  academyBucketName: 'orgbrain-beta-site',
  debug: true,
  monitorFrontendUrl: 'https://monitor.orgbrain.ai?target=devel',
  meetingEmailRecipientDomain: 'staging-meeting.orgbrain.ai',
  logoURL: 'https://orgbrain-preprod-site.s3.eu-central-1.amazonaws.com/',
  vapidPubKey:
    'BFgOaLG4mMaNglU4un2hG43LpekcCp0dBDzi67T86zSEZQFzTTfT7v2M8tp1LUgsl133JvXfD6-0a47lEPfpLRE',
  bankid2FA: true,
  bankidSIGNICAT: {
    client_id: 'test.kycsec.orgbrain.38990',
    // redirect: `$https://preprod.staging.orgbrain.ai/auth/index.html`,
    redirectPath: `/auth/index.html`,
    auth: 'nbid',
    authURL: 'https://preprod.signicat.com/oidc/authorize',
    // redirectPath: `/auth/index.html`,
  },

  bankid: {
    client_id: 'urn:my:application:identifier:890424',
    redirectPath: `/auth`,
    auth: 'nbid',
    authURL: 'https://orgbrain.criipto.id/oauth2/authorize',
  },

  azets: {
    client_id: 'orgbrain_sso',
    redirect: `${APP_BASE_URL}`,
    authURL: 'https://idp-develop-devdb.staging.cozone.com/oauth/login',
    jwksURL: 'https://idp-develop-devdb.staging.cozone.com/api/v1/oauth2/jwks',
  },

  loguncaughtErrors: false,
  adobe_embedded_key: '5b7c54a30d974e77bbd1b4676906fb03',
};

export const environment = Object.assign(sharedenvironment, myenvironment);
