// used in apps/backend-monitor/src/app/splash/setSplash.ts

// This is a good place to see what ll the front end endpoints are.
// There are also some backend on enpoints.
//

export enum FrontendEnum {
  'apps.orgbrain.ai' = 'apps.orgbrain.ai',
  'staging.orgbrain.ai' = 'staging.orgbrain.ai',
  'testing.prod.orgbrain.ai' = 'testing.prod.orgbrain.ai',
  'rollback-server.orgbrain.ai' = 'rollback-server.orgbrain.ai',
  'debug-prod.orgbrain.ai' = 'debug-prod.orgbrain.ai',
  'devel.staging.orgbrain.ai' = 'devel.staging.orgbrain.ai',
  'preprod.staging.orgbrain.ai' = 'preprod.staging.orgbrain.ai',
  'preprod2.orgbrain.ai' = 'preprod2.orgbrain.ai',
  'proto.orgbrain.ai' = 'proto.orgbrain.ai',
  'localhost' = 'localhost',
  'null' = 'null',
}

export const frontendNamesProd: FrontendEnum[] = [
  FrontendEnum['apps.orgbrain.ai'],
  FrontendEnum['staging.orgbrain.ai'],
  FrontendEnum['testing.prod.orgbrain.ai'],
  FrontendEnum['rollback-server.orgbrain.ai'],
  FrontendEnum['debug-prod.orgbrain.ai'],
];

export const frontendNamesStaging = [
  FrontendEnum['devel.staging.orgbrain.ai'],
  FrontendEnum['preprod2.orgbrain.ai'],
  FrontendEnum['preprod.staging.orgbrain.ai'],
  FrontendEnum['localhost'],
];

// Enumerate the different servers for each configuration
export enum ServerUrlEnum {
  ADMIN = 'ADMIN',
  GRAPHQL = 'GRAPHQL',
  GRAPHQL_ENDPOINT = 'GRAPHQL_ENDPOINT',
  GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT = 'GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT',
  PDF = 'PDF',
  FILE = 'FILE',
  WEBPUSH = 'WEBPUSH',
  MAIN = 'MAIN',
  TAX = 'TAX',
  MONITOR = 'MONITOR',
}

// Enumerate the possible values for the server configurations

export enum ServerConfigEnum {
  PRODUCTION_A_SERVERS = 'PRODUCTION_A_SERVERS',
  PRODUCTION_B_SERVERS = 'PRODUCTION_B_SERVERS',
  PREPROD_SERVERS = 'PREPROD_SERVERS',
  PREPROD2_SERVERS = 'PREPROD2_SERVERS',
  DEVEL_SERVERS = 'DEVEL_SERVERS',
  DEBUG_SERVERS = 'DEBUG_SERVERS',
  TESTPROD_SERVERS = 'TESTPROD_SERVERS',
  ROLLBACK_SERVERS = 'ROLLBACK_SERVERS',
  LOCAL_SERVERS = 'LOCAL_SERVERS',
  NULL_SERVERS = 'NULL_SERVERS',
  PROTO_SERVERS = 'PROTO_SERVERS',
}

export const defaultUrlsForFrontEnd: { [frontend: string]: ServerConfigEnum } = {
  'apps.orgbrain.ai': ServerConfigEnum.PRODUCTION_A_SERVERS,
  'staging.orgbrain.ai': ServerConfigEnum.PRODUCTION_B_SERVERS,
  'testing.prod.orgbrain.ai': ServerConfigEnum.TESTPROD_SERVERS,
  'rollback-server.orgbrain.ai': ServerConfigEnum.ROLLBACK_SERVERS,
  'debug-prod.orgbrain.ai': ServerConfigEnum.DEBUG_SERVERS,
  'devel.staging.orgbrain.ai': ServerConfigEnum.DEVEL_SERVERS,
  'preprod.staging.orgbrain.ai': ServerConfigEnum.PREPROD_SERVERS,
  'preprod2.orgbrain.ai': ServerConfigEnum.PREPROD2_SERVERS,
  localhost: ServerConfigEnum.LOCAL_SERVERS,
};

export const serverConfigsProd: ServerConfigEnum[] = [
  ServerConfigEnum.PRODUCTION_A_SERVERS,
  ServerConfigEnum.PRODUCTION_B_SERVERS,
  ServerConfigEnum.ROLLBACK_SERVERS,
  ServerConfigEnum.TESTPROD_SERVERS,
  ServerConfigEnum.DEBUG_SERVERS,
];

export const serverConfigsStaging: ServerConfigEnum[] = [
  ServerConfigEnum.PREPROD2_SERVERS,
  ServerConfigEnum.PREPROD_SERVERS,
  ServerConfigEnum.LOCAL_SERVERS,
  ServerConfigEnum.PROTO_SERVERS,
];

export type UrlMap = { [key in ServerUrlEnum]: string };

// Define the type of the map of server configurations for frontends.
//
export type ServerConfigUrlMaps = { [key in ServerConfigEnum]: UrlMap };

export const staticServers = {
  TAX: 'https://taxdata-server.orgbrain.ai/tax',
  PRODUCTION_MAIN: 'https://prod-server.orgbrain.ai/admin',
  PREPROD_MAIN: 'https://preprod-server.orgbrain.ai/admin',
  PROTO_MAIN: 'https://proto.orgbrain.ai/admin',
  LOCAL_MAIN: 'http://localhost:3001', // Just use normal admin server so we don't have to run two servers
};

export const serverUrlConfigMaps: ServerConfigUrlMaps = {
  PRODUCTION_A_SERVERS: {
    ADMIN: 'https://production-admin.orgbrain.ai/admin',
    GRAPHQL: 'https://production-graphql.orgbrain.ai/graphql',
    GRAPHQL_ENDPOINT: 'https://production-graphql.orgbrain.ai/graphql/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'wss://production-graphql.orgbrain.ai/wsgraphql/graphql',
    PDF: 'https://production-pdf.orgbrain.ai/admin',
    FILE: 'https://production-admin.orgbrain.ai/admin',
    WEBPUSH: 'https://prod-server.orgbrain.ai/admin/web-push',
    MAIN: staticServers.PRODUCTION_MAIN,
    TAX: staticServers.TAX,
    MONITOR: 'https://monitor-server.orgbrain.ai/production',
  },

  TESTPROD_SERVERS: {
    ADMIN: 'https://test-prod.orgbrain.ai/admin',
    GRAPHQL: 'https://test-prod.orgbrain.ai/graphql',
    GRAPHQL_ENDPOINT: 'https://test-prod.orgbrain.ai/graphql/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'wss://test-prod.orgbrain.ai/wsgraphql/graphql',
    PDF: 'https://test-prod.orgbrain.ai/admin',
    FILE: 'https://test-prod.orgbrain.ai/admin',
    WEBPUSH: 'https://test-prod.orgbrain.ai/admin/web-push',
    MAIN: staticServers.PRODUCTION_MAIN,
    TAX: staticServers.TAX,
    MONITOR: 'https://monitor-server.orgbrain.ai/production',
  },

  PRODUCTION_B_SERVERS: {
    ADMIN: 'https://test-admin.orgbrain.ai/admin',
    GRAPHQL: 'https://test-graphql.orgbrain.ai/graphql',
    GRAPHQL_ENDPOINT: 'https://test-graphql.orgbrain.ai/graphql/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'wss://test-graphql.orgbrain.ai/wsgraphql/graphql',
    PDF: 'https://test-pdf.orgbrain.ai/admin',
    FILE: 'https://test-admin.orgbrain.ai/admin',
    WEBPUSH: 'https://test-admin.orgbrain.ai/admin/web-push',
    MAIN: staticServers.PRODUCTION_MAIN,
    TAX: staticServers.TAX,
    MONITOR: 'https://monitor-server.orgbrain.ai/production',
  },

  ROLLBACK_SERVERS: {
    ADMIN: 'https://rollback-server.orgbrain.ai/admin',
    GRAPHQL: 'https://rollback-server.orgbrain.ai/graphql',
    GRAPHQL_ENDPOINT: 'https://rollback-server.orgbrain.ai/graphql/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'wss://rollback-server.orgbrain.ai/wsgraphql/graphql',
    PDF: 'https://rollback-server.orgbrain.ai/admin',
    FILE: 'https://rollback-server.orgbrain.ai/admin',
    WEBPUSH: 'https://rollback-server.orgbrain.ai/admin/web-push',
    MAIN: staticServers.PRODUCTION_MAIN,
    TAX: staticServers.TAX,
    MONITOR: 'https://monitor-server.orgbrain.ai/production',
  },

  DEBUG_SERVERS: {
    ADMIN: 'https://debug-prod.orgbrain.ai/admin',
    GRAPHQL: 'https://debug-prod.orgbrain.ai/graphql',
    GRAPHQL_ENDPOINT: 'https://debug-prod.orgbrain.ai/graphql/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'wss://debug-prod.orgbrain.ai/wsgraphql/graphql',
    PDF: 'https://debug-prod.orgbrain.ai/admin',
    FILE: 'https://debug-prod.orgbrain.ai/admin',
    WEBPUSH: 'https://debug-prod.orgbrain.ai/admin/web-push',
    MAIN: staticServers.PRODUCTION_MAIN,
    TAX: staticServers.TAX,
    MONITOR: 'https://monitor-server.orgbrain.ai/production',
  },

  PROTO_SERVERS: {
    ADMIN: 'https://proto.orgbrain.ai/admin',
    GRAPHQL: 'https://proto.orgbrain.ai/graphql',
    GRAPHQL_ENDPOINT: 'https://proto.orgbrain.ai/graphql/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'wss://proto.orgbrain.ai/wsgraphql/graphql',
    PDF: 'https://proto.orgbrain.ai/admin',
    FILE: 'https://proto.orgbrain.ai/admin',
    WEBPUSH: 'https://proto.orgbrain.ai/admin/web-push',
    MAIN: staticServers.PROTO_MAIN,
    TAX: staticServers.TAX,
    MONITOR: 'https://monitor-server.orgbrain.ai/preprod',
  },

  PREPROD_SERVERS: {
    ADMIN: 'https://preprod-server.orgbrain.ai/admin',
    GRAPHQL: 'https://preprod-server.orgbrain.ai/graphql',
    GRAPHQL_ENDPOINT: 'https://preprod-server.orgbrain.ai/graphql/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'wss://preprod-server.orgbrain.ai/wsgraphql/graphql',
    PDF: 'https://preprod-server.orgbrain.ai/admin',
    FILE: 'https://preprod-server.orgbrain.ai/admin',
    WEBPUSH: 'https://preprod-server.orgbrain.ai/admin/web-push',
    MAIN: staticServers.PREPROD_MAIN,
    TAX: staticServers.TAX,
    MONITOR: 'https://monitor-server.orgbrain.ai/preprod',
  },

  PREPROD2_SERVERS: {
    ADMIN: 'https://preprod2-server.orgbrain.ai/admin',
    GRAPHQL: 'https://preprod2-server.orgbrain.ai/graphql',
    GRAPHQL_ENDPOINT: 'https://preprod2-server.orgbrain.ai/graphql/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'wss://preprod2-server.orgbrain.ai/wsgraphql/graphql',
    PDF: 'https://preprod2-server.orgbrain.ai/admin',
    FILE: 'https://preprod2-server.orgbrain.ai/admin',
    WEBPUSH: 'https://preprod2-server.orgbrain.ai/admin/web-push',
    MAIN: staticServers.PREPROD_MAIN,
    TAX: staticServers.TAX,
    MONITOR: 'https://monitor-server.orgbrain.ai/preprod',
  },

  DEVEL_SERVERS: {
    // CURRENTLY BROKE I THINK
    ADMIN: 'https://devel-server.orgbrain.ai/admin',
    GRAPHQL: 'https://devel-server.orgbrain.ai/graphql',
    GRAPHQL_ENDPOINT: 'https://devel-server.orgbrain.ai/graphql/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'wss://devel-server.orgbrain.ai/wsgraphql/graphql',
    PDF: 'https://devel-server.orgbrain.ai/admin',
    FILE: 'https://devel-server.orgbrain.ai/admin',
    WEBPUSH: 'https://devel-server.orgbrain.ai/admin/web-push',
    MAIN: staticServers.PREPROD_MAIN,
    TAX: staticServers.TAX,
    MONITOR: 'https://monitor-server.orgbrain.ai/devel',
  },

  LOCAL_SERVERS: {
    ADMIN: 'http://localhost:3001',
    GRAPHQL: 'http://localhost:3333',
    GRAPHQL_ENDPOINT: 'http://localhost:3333/graphql',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'ws://localhost:3333/graphql',
    PDF: 'http://localhost:3001',
    FILE: 'http://localhost:3001',
    WEBPUSH: 'http://localhost:3001/web-push',
    MAIN: 'http://localhost:3001',
    TAX: 'https://taxdata-server.orgbrain.ai/tax',
    MONITOR: 'http://localhost:4005',
  },

  NULL_SERVERS: {
    ADMIN: 'http://0.0.0.0',
    GRAPHQL: 'http://0.0.0.0',
    GRAPHQL_ENDPOINT: 'http://0.0.0.0',
    GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: 'ws://0.0.0.0',
    PDF: 'http://0.0.0.0',
    FILE: 'http://0.0.0.0',
    WEBPUSH: 'http://0.0.0.0',
    MAIN: 'http://localhost:3001',
    TAX: 'http://0.0.0.0',
    MONITOR: null,
  },
};

export type ServerUrlsPayload = { [deploy: string]: ServerConfigEnum };

export type DeployInfo = {
  BUILD_TIMESTAMP: string;
  BUILD_BRANCH: string;
  BUILD_WHO: string;
  ORGBRAIN_VERSION: string;
  COMMIT: string;
  COMMIT_DATE: string;
  MESSAGE: string;
};

export enum AppKeyEnum {
  'orgbrain' = 'orgbrain',
  'backend-admin' = 'backend-admin',
  'backend-graphql' = 'backend-graphql',
}

export type VersionListItem = {
  hash: any;
  author: any;
  date: any;
  version: string;
  message: string;
};

export type DeployStructure = {
  data: { [key in AppKeyEnum]: DeployInfo[] };
  list: VersionListItem[];
};

export type VersionData = {
  list: VersionListItem[];
  versionsMap: any;
};

export type SplashSetPayload = {
  html: string;
  serverConfig: { frontend: string; servers: ServerConfigEnum };
  stamp: number;
};

export type SplashRecordServerConfigs = { [frontend: string]: ServerConfigEnum };
export type SplashRecord = {
  _id?: any;
  html: string;
  serverConfigs: SplashRecordServerConfigs;
  stamp: number;
};

export const signicatRedirectUrls = [
  'https://testing.prod.orgbrain.ai/auth/index.html',
  'https://apps.orgbrain.ai/auth/index.html',
  'https://devel.staging.orgbrain.ai/auth/index.html',
  'https://d185tl9g87mzff.cloudfront.net/auth/',
  'https://preprod.staging.orgbrain.ai/auth/index.html',
  'https://d462kiu0a5p6i.cloudfront.net/auth/',
  'http://localhost:4200/auth',
];
