// DEPENDENT ON ORG TYPE. Used in method translateText
import { texts } from './texts/optional-translation.texts';

// NOT DEPENDENT ON ORG TYPE. Used in methods translate, localeTranslate
import { textMappings } from './texts/text-mappings';
// SAME AS ABOVE
import { textMappings as textMappingsFIlebrowser } from './texts/filebrowser-text-mapping';
import { CompanyAcronymsEnum, companyAcronymsMap, CompanyTerminologyEnum } from '@orgbrain/roles';

const combinedTestMappings = { ...textMappings, ...textMappingsFIlebrowser };

type TranslationMap = {
  en: string;
  nb: string;
};

type TranslationTypeMap = {
  default: TranslationMap;
  fliAlike: TranslationMap;
};

export function getPackTypeHelper(orgType: CompanyAcronymsEnum): CompanyTerminologyEnum {
  if (!companyAcronymsMap[orgType]) {
    return CompanyTerminologyEnum.default;
  } else {
    return companyAcronymsMap[orgType].terminolgy;
  }
}

// orgType = orgType || '';
// switch (orgType) {
//   case 'FLI':
//   case 'SA':
//   case 'BA':
//   case 'STI':
//   case 'FKF':
//   case 'KF':
//   case 'IKS':
//   case 'ANS':
//   case 'DA':
//     return 'fliAlike';
//   case 'BRL':
//     return 'BRL';
//   case 'ESEK':
//     return 'ESEK';
//   case 'AS':
//     return 'AS';
//   case 'SUBGR-DD':
//     return 'SUBGR-DD';
//   default:
//     if (orgType.startsWith('SUBGR-')) {
//       return 'SUBGR';
//     } else {
//       return 'default';
//     }
// }
// }

export class OptionalTranslationHelper {
  constructor(public orgType: CompanyAcronymsEnum, public locale: string, public org?: any) {}

  public getPackType(orgType = this.orgType): CompanyTerminologyEnum {
    const OT = orgType || this.orgType;
    return getPackTypeHelper(OT);
  }

  private performTranslation(
    translations: TranslationTypeMap,
    locale = this.locale,
    orgType = this.orgType
  ) {
    const packType = this.getPackType(orgType);
    const myPack =
      translations[orgType] ||
      translations[packType] ||
      translations['alternative'] ||
      translations['default'];
    return myPack[locale] || myPack['en'];
  }

  // It's for Org Type Translation (FLI/BRL/Other)
  // It DOES NOT work with textMappings
  public translateText(keyword, locale = null, orgType = null, mytexts = null) {
    let pack;
    locale = locale || this.locale;
    orgType = orgType || this.orgType;
    if (mytexts) {
      pack = mytexts[keyword];
    }

    if (!pack) {
      pack = texts[keyword];
    }

    return pack ? this.performTranslation(pack, locale, orgType) : keyword;
  }

  // Came from OptionalTranslationService
  // Usage: this.optionalTranslationService.translate('isSubsidiaryText', options)
  // Every option is optional. Can be null, like translate('isSubsidiaryText')
  // Example: if you need variables and jurisdiction in your  translation, usage is like

  /*
   *  const options = {
   *    variables: {
   *      orgName: org.name,
   *    },
   *    jurisdiction: OrgJurisdiction.NO
   *  };
   */

  // Then in translations that variable should wrapped like this: {{orgName}}

  // In most cases, does not care about org type. But for jurisdiction translations may also use org type.
  // In general, this method is more flexible
  translate(key, { nameType, locale, jurisdiction, companyType, variables } = {} as any) {
    const proceedWithTranslation =
      typeof nameType !== 'string' ||
      nameType === 'KEY' ||
      nameType === 'KEY_MUTABLE' ||
      nameType === 'PERMISSION';

    if (!proceedWithTranslation) {
      return key;
    }

    const finalLocale = locale || this.locale;
    let finalKey = jurisdiction ? `${key}?jurisdiction=${jurisdiction}` : key;
    finalKey = companyType ? `${finalKey}?companyType=${companyType}` : finalKey;

    let translation = combinedTestMappings[finalKey] && combinedTestMappings[finalKey][finalLocale];
    if (!translation) {
      translation = combinedTestMappings[finalKey] && combinedTestMappings[finalKey]['en'];
    }

    let translationWithOptionalReplacements =
      translation || this.translateText(finalKey, finalLocale, companyType);

    if (variables) {
      const keys = Object.keys(variables);
      for (const key of keys) {
        const find = `{{${key}}}`;
        const re = new RegExp(find, 'g');
        translationWithOptionalReplacements = translationWithOptionalReplacements.replace(
          re,
          variables[key]
        );
      }
    }
    return translationWithOptionalReplacements;
  }

  // Came from OptionalTranslationService
  localeTranslate(key: string, locale: string) {
    return this.translate(key, { locale });
  }

  isFliAlike(): boolean {
    return this.getPackType() === CompanyTerminologyEnum.fliAlike;
  }

  isSpecialCaseCompany() {
    return this.isFliAlike() || ['BRL', 'ESEK'].includes(this.orgType);
  }

  isAsaAlike() {
    return this.orgType === 'ASA';
  }
  isAsLike() {
    return this.orgType === 'AS' || this.orgType === 'ASA';
  }
}

export const localeLessTranslator = new OptionalTranslationHelper(null as CompanyAcronymsEnum, '');
