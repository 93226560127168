export const DEFAULT_ORG_ID = 'ORGBRAIN_DEFAULT';
export const APP_SELECTOR_SCREEN = '/select';
export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';
export const ERROR_THROW_ME = 'X-Throw-Error';
export const NO_LOGOUT_HEADER = 'X-Nologout-Interceptor';
export const CHANNEL_PULSE_PERIOD = 30000; // How often rtoi send HEARTBEATS
export const CHANNEL_PULSE_PERIOD_POLL = 1000; // Keep checcking at this rate
// Numbers, letters, dashes
export const ORG_ID_REPLACE_REGEXP = /[^0-9a-zA-Z]/g;
export const ORG_ID_ALLOWED_REGEXP = /^[0-9a-zA-Z]+$/;
// https://invisible-characters.com/
// U+205F MEDIUM MATHEMATICAL SPACE
export const INVISIBLE_CHAR_SPACE_ALIKE = ' ';
// U+2008 PUNCTUATION SPACE
export const INVISIBLE_CHAR_SPACE_ALIKE2 = ' ';
// https://en.wikipedia.org/wiki/Zero-width_space
export const ZERO_WIDTH_SPACE = '​';
